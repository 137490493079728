hr {
  display: block;
  height: 1px;
  border: 0;
  border-bottom: 1px solid fade(#fff,10%);
  margin: 1em 0;
  padding: 0;
}

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle;
}

p {
  font-family: @font-family-lato-2;
}

fieldset {
  border: 0;
  margin: 0;
  padding: 0;
}

button, input {
  -webkit-appearance: none;
  -moz-appearance:    none;
  appearance:         none;
}

textarea {
  resize: vertical;
}

html {
  height: 100%;
}

::selection {
  color: #fff;
  background: @main-color-2;
}

@-moz-document url-prefix() {
  @media @960 {
    body > #body {
      -moz-transform: scale(0.8);
      -moz-transform-origin: 0 0 0;
      width: 125%;
    }
  }
}

body {
  opacity: 1 !important;
  background: @background !important;
  font-size: @font-size-initial;
  font-family: @font-family-lato;
  cursor: default;
  color: @main-color;
  min-height: 100%;
  position: relative;
  font-weight: lighter;
  &.no-scroll {
    overflow: hidden;
  }
  .fixed-bg {
    display: none;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    background: #333;
    background: rgba(0, 0, 0, 0.7);
    z-index: 1002;
    &.detail {
      background: @detail-color;
      background: fade(@detail-color, 95%);
      html.zanzibar &{
        background: fade(@main-color-6, 95%);
      }
    }
  }
  > #body {
    min-height: 100%;
    overflow-x: hidden;
    position: relative;
  }
  @media @960 {
    zoom: 0.8;
  }
  @media @768 {
    zoom: 1;
  }
  @media @480 {
    font-size: 0.9em;
  }
  @media @360 {
    font-size: 0.8em;
  }
}

a[href] {
  color: @link-color;
  text-decoration: none;
  transition: @transition-color;
  &:hover {
    color: @link-color-hover;
  }
}
.icomoon {
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.container {
  width: @container;
  margin: 0 auto;
  display: table;
  padding: 0 @container-padding;
  box-sizing: border-box;
  &.big {
    width: 100%;
    padding: 0;
    overflow: hidden;
  }
  &.small {
    width: 1170px;
    padding: 0;
    @media @1024 {
      width: 970px;
    }
    @media @768 {
      width: 66.66% !important;
    }
    @media @480 {
      width: 75% !important;
    }
    @media @360 {
      width: 100% !important;
    }
  }
  @media @1024 {
    width: @container-1;
    padding: 0 @container-1-padding;
  }
  @media @768 {
    width: @container-2 !important;
    padding: 0 @container-2-padding !important;
  }
  @media @480 {
    width: 100% !important;
    padding: 0 @container-3-padding !important;
  }
  @media @360 {
    padding: 0 @container-4-padding !important;
  }
  @media @320 {
    padding: 0 @container-5-padding !important;
  }
}

.heading-type-1 {
  .heading {
    font-size: @font-size-40;
    line-height: 46px;
    color: @main-color-11;
    html.zanzibar &{
      color: @main-color-6-footer!important;
      font-family:@font-fertigo;
    }
    font-family: @font-teimer;
    text-transform: uppercase;
    text-align: center;
    font-weight: @font-normal;
    margin: 60px 0 20px;
    @media @360 {
      font-size: @font-size-35;
      line-height: 32px;
      margin: 30px 0 20px;
    }
    html.zanzibar & {
      color: @main-color-4;
    }
  }
  html.zanzibar &{
    .orange{
      color: @main-color-4!important;
    }
  }
  .sub-heading {
    border-bottom: 1px solid @main-color-11;
    html.zanzibar &{
      border-bottom: none;
    }
    padding: 0 0 20px;
    margin: 15px auto 75px;
    display: table;
    a {
      font-size: @font-size-20;
      color: @main-color-11;
      margin: 0 22px;
      html.zanzibar &{
        color:tint(@link-color,20%);
      }

      @media @768 {
        margin: 0 10px;
      }
      @media @480 {
        margin: 0 22px 10px;
        display: block;
        text-align: center;
      }
      &:hover, &.active {
        color: @main-color-4;
        html.zanzibar &{
          color: @main-color-6-footer;
        }
      }
    }
  }
  p {
    font-size: @font-size-19;
    line-height: 27px;
    color: @main-color;
    text-align: center;
    margin: 0 auto 30px;
    .col-8;
    @media @768 {
      width: 100%;
    }
    @media @360 {
      text-align: justify;
    }
  }
}

@heading-line-color: @main-color-1;
html.zanzibar &{
  @heading-line-color: @main-color-4;
}
@height: 2px;
.heading-line (@heading-line-color, @height) {
  position: relative;
  display: block;
  margin: 0 auto;
  width: 60px;
  height: @height;
  background: @heading-line-color;
  &:before, &:after {
    content: " ";
    display: block;
    width: 100px;
    height: @height;
    position: absolute;
  }
  &:before {
    left: -100px;
    background: @heading-line-color;
    background: -moz-linear-gradient(left,  rgba(0,0,0,0) 0%, @heading-line-color 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(0,0,0,0)), color-stop(100%,@heading-line-color)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,  rgba(0,0,0,0) 0%,@heading-line-color 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left,  rgba(0,0,0,0) 0%,@heading-line-color 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left,  rgba(0,0,0,0) 0%,@heading-line-color 100%); /* IE10+ */
    background: linear-gradient(to right,  rgba(0,0,0,0) 0%,@heading-line-color 100%); /* W3C */
    html.zanzibar & {
      background: @main-color-4 !important;
    }
  }
  &:after {
    right: -100px;
    background: @heading-line-color;
    background: -moz-linear-gradient(left,  @heading-line-color 0%, rgba(0,0,0,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,@heading-line-color), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,  @heading-line-color 0%,rgba(0,0,0,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left,  @heading-line-color 0%,rgba(0,0,0,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left,  @heading-line-color 0%,rgba(0,0,0,0) 100%); /* IE10+ */
    background: linear-gradient(to right,  @heading-line-color 0%,rgba(0,0,0,0) 100%); /* W3C */
    html.zanzibar & {
      background: @main-color-4 !important;
    }
  }
}

.heading-line {
  .heading-line (@heading-line-color, @height);
  display: none;
  html.zanzibar &{
    .heading-line (@main-color-4, @height);
    display: none;
  }
}

.heading-line-big (@heading-line-color, @height) {
  .heading-line (@heading-line-color, @height);
  width: 85%;
}

.heading-line-big {
  .heading-line-big (@heading-line-color, @height);
}

@heading-line-color: @main-color-1;
.heading-sign-1(@heading-line-color) {
  margin: 0 auto;
  display: table;
  @heading-line-background-1: fade(@page-background, 100%);
  @heading-line-background-2: fade(@page-background, 0%);
  font-size: 36px;
  color: @heading-line-color;
  position: relative;
  &:after {
    content: " ";
    display: block;
    width: 100%;
    height: 36px;
    position: absolute;
    top: 0;
    left: 0;
    background: -moz-linear-gradient(left,  @heading-line-background-1 0%, @heading-line-background-2 50%, @heading-line-background-1 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, right top, color-stop(0%,@heading-line-background-1), color-stop(50%,@heading-line-background-2), color-stop(100%,@heading-line-background-1)); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(left,  @heading-line-background-1 0%,@heading-line-background-2 50%,@heading-line-background-1 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(left,  @heading-line-background-1 0%,@heading-line-background-2 50%,@heading-line-background-1 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(left,  @heading-line-background-1 0%,@heading-line-background-2 50%,@heading-line-background-1 100%); /* IE10+ */
    background: linear-gradient(to right,  @heading-line-background-1 0%,@heading-line-background-2 50%,@heading-line-background-1 100%); /* W3C */
  }
}

.heading-sign-1 {
  .heading-sign-1(@heading-line-color);
  display: none !important;
}

.heading-sign-2 {
  color: @main-color-1;
  margin-bottom: 40px !important;
  display: none !important;
}

.btn-type-1 (@main-color-1, @main-color-2, @main-color-5, @font-size-20) {
  display: block;
  border: 0;
  border-radius: 2px;
  padding: 12px 20px;
  box-sizing: border-box;
  text-align: center;
  background: @main-color-4;
  color: #fff !important;
  font-size: @font-size-14;
  letter-spacing: 0.012em;
  font-weight: @font-bold;
  transition: @transition-color, @transition-background !important;
  font-family: @font-family-new;
  text-transform: uppercase;
  &:hover, &.active {
    background: @main-color-13;
    color: @main-color-7 !important;
  }
  @media @480 {
    display: block;
    text-align: center;
    min-width: 0;
    width: 100%;
  }
}
.btn-type-1 {
  .btn-type-1 (@main-color-1, @main-color-2, @main-color-5, @font-size-20);
  html.zanzibar &{
    font-size: @font-size-15!important;
    color:@main-color-8!important;
    font-weight: normal!important;
  }
}

.btn-type-2 {
  display: table;
  padding: 12px 20px;
  box-sizing: border-box;
  text-align: center;
  background: @main-color-4;
  color: @main-color-7 !important;
  font-size: @font-size-14;
  font-family: @font-family-new;
  font-weight: @font-medium;
  text-transform: uppercase;
  background: transparent;
  transition: background-position 0.5s, @transition-background, @transition-color !important;
  border: 1px solid #fff;
  border-radius: 2px;
  html.zanzibar &{
    background: none !important;
    border:1px solid @main-color-4;
  }
  &:hover {
    background: fade(@main-color-7,15%);
    color: #fff;
  }
  @media @480 {
    display: table;
    text-align: center;
    min-width: 0;
    width: 100%;
  }
}

.slick-prev {
  width: auto;
  height: auto;
  z-index: 1;
  &:before {
    .icomoon;
    content: @icon-chevron-left-light;
    font-size: 36px;
  }
}
.slick-next {
  width: auto;
  height: auto;
  z-index: 1;
  &:before {
    .icomoon;
    content: @icon-chevron-right-light;
    font-size: 36px;
  }
}

html.zanzibar &{
  .logo-contact{
    max-height: 135px;
    @media @768 {
      max-height: 70px;
      padding: 0;
    }
  }
  .orange{
    color: @main-color-4!important;
  }
}

.icon-arrow-2-right,.icon-arrow-2-left {

  html.zanzibar &{
    color: fade(#fff,50%)!important;
    &:hover{
      color: @main-color-4!important;
      .another-room{
        color: @main-color-4!important;
      }
    }
  }
}
