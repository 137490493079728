@font-face {
  font-family: 'icomoon';
  src:url('../fonts/icomoon.eot?56nubn');
  src:url('../fonts/icomoon.eot?#iefix56nubn') format('embedded-opentype'),
  url('../fonts/icomoon.ttf?56nubn') format('truetype'),
  url('../fonts/icomoon.woff?56nubn') format('woff'),
  url('../fonts/icomoon.svg?56nubn#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  .icomoon;
  font-family: 'icomoon';
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
@icon-denni-uklid: "\e90c";
@icon-kavovar: "\e90d";
@icon-mycka: "\e90e";
@icon-nekuracke-prostredi: "\e90f";
@icon-pocet-loznic: "\e910";
@icon-pracka: "\e911";
@icon-rozmer-mistnosti: "\e912";
@icon-rozmery-postele: "\e913";
@icon-susicka: "\e914";
@icon-toaletni-potreby: "\e915";
@icon-vyhled: "\e916";
@icon-dressing-room: "\e90a";
@icon-fireplace: "\e90b";
@icon-shower: "\e901";
@icon-bottle: "\e902";
@icon-single-bed: "\e903";
@icon-double-bed: "\e904";
@icon-sofa: "\e905";
@icon-table: "\e906";
@icon-kitchen: "\e907";
@icon-packa: "\e900";
@icon-closed: "\e626";
@icon-plane: "\e62b";
@icon-bus: "\e62c";
@icon-train: "\e62d";
@icon-car: "\e62e";
@icon-zvon-logo-2: "\e620";
@icon-tripadvisor: "\e61f";
@icon-facebook: "\e611";
@icon-google-plus: "\e612";
@icon-instagram: "\e613";
@icon-linkedin: "\e614";
@icon-pinterest: "\e615";
@icon-services-1: "\e616";
@icon-services-2: "\e617";
@icon-services-3: "\e618";
@icon-services-4: "\e619";
@icon-services-5: "\e61a";
@icon-services-6: "\e61b";
@icon-services-7: "\e61c";
@icon-twitter: "\e61d";
@icon-youtube: "\e61e";
@icon-sign-2: "\e600";
@icon-sign-1: "\e603";
@icon-zvon-logo-small: "\e606";
@icon-zvon-logo: "\e609";
@icon-view: "\e908";
@icon-bath: "\e909";
@icon-zoom: "\e601";
@icon-menu: "\e602";
@icon-chevron-right-light: "\e604";
@icon-chevron-left-light: "\e605";
@icon-bell: "\e607";
@icon-calendar: "\e608";
@icon-chevron-left: "\e60a";
@icon-chevron-right: "\e60b";
@icon-chevron-up: "\e60c";
@icon-chevron-down: "\e60d";
@icon-map: "\e60e";
@icon-mail: "\e60f";
@icon-phone: "\e610";
@icon-arrow-2-top: "\e627";
@icon-arrow-2-down: "\e628";
@icon-arrow-2-right: "\e629";
@icon-arrow-2-left: "\e62a";
@icon-arrow-right: "\e622";
@icon-arrow-left: "\e623";
@icon-arrow-down: "\e624";
@icon-arrow-up: "\e625";
@icon-close: "\e621";


.icon-denni-uklid {
  &:before {
    content: @icon-denni-uklid;
  }
}
.icon-kavovar {
  &:before {
    content: @icon-kavovar;
  }
}
.icon-mycka {
  &:before {
    content: @icon-mycka;
  }
}
.icon-nekuracke-prostredi {
  &:before {
    content: @icon-nekuracke-prostredi;
  }
}
.icon-pocet-loznic {
  &:before {
    content: @icon-pocet-loznic;
  }
}
.icon-pracka {
  &:before {
    content: @icon-pracka;
  }
}
.icon-rozmer-mistnosti {
  &:before {
    content: @icon-rozmer-mistnosti;
  }
}
.icon-rozmery-postele {
  &:before {
    content: @icon-rozmery-postele;
  }
}
.icon-susicka {
  &:before {
    content: @icon-susicka;
  }
}
.icon-toaletni-potreby {
  &:before {
    content: @icon-toaletni-potreby;
  }
}
.icon-vyhled {
  &:before {
    content: @icon-vyhled;
  }
}
.icon-dressing-room {
  &:before {
    content: @icon-dressing-room;
  }
}
.icon-fireplace {
  &:before {
    content: @icon-fireplace;
  }
}
.icon-shower {
  &:before {
    content: @icon-shower;
  }
}
.icon-bottle {
  &:before {
    content: @icon-bottle;
  }
}
.icon-single-bed {
  &:before {
    content: @icon-single-bed;
  }
}
.icon-double-bed {
  &:before {
    content: @icon-double-bed;
  }
}
.icon-sofa {
  &:before {
    content: @icon-sofa;
  }
}
.icon-table {
  &:before {
    content: @icon-table;
  }
}
.icon-kitchen {
  &:before {
    content: @icon-kitchen;
  }
}
.icon-packa {
  &:before {
    content: @icon-packa;
  }
}
.icon-closed {
  &:before {
    content: @icon-closed;
  }
}
.icon-plane {
  &:before {
    content: @icon-plane;
  }
}
.icon-bus {
  &:before {
    content: @icon-bus;
  }
}
.icon-train {
  &:before {
    content: @icon-train;
  }
}
.icon-car {
  &:before {
    content: @icon-car;
  }
}
.icon-zvon-logo-2 {
  &:before {
    content: @icon-zvon-logo-2;
  }
}
.icon-tripadvisor {
  &:before {
    content: @icon-tripadvisor;
  }
}
.icon-facebook {
  &:before {
    content: @icon-facebook;
  }
}
.icon-google-plus {
  &:before {
    content: @icon-google-plus;
  }
}
.icon-instagram {
  &:before {
    content: @icon-instagram;
  }
}
.icon-linkedin {
  &:before {
    content: @icon-linkedin;
  }
}
.icon-pinterest {
  &:before {
    content: @icon-pinterest;
  }
}
.icon-services-1 {
  &:before {
    content: @icon-services-1;
  }
}
.icon-services-2 {
  &:before {
    content: @icon-services-2;
  }
}
.icon-services-3 {
  &:before {
    content: @icon-services-3;
  }
}
.icon-services-4 {
  &:before {
    content: @icon-services-4;
  }
}
.icon-services-5 {
  &:before {
    content: @icon-services-5;
  }
}
.icon-services-6 {
  &:before {
    content: @icon-services-6;
  }
}
.icon-services-7 {
  &:before {
    content: @icon-services-7;
  }
}
.icon-twitter {
  &:before {
    content: @icon-twitter;
  }
}
.icon-youtube {
  &:before {
    content: @icon-youtube;
  }
}
.icon-sign-2 {
  &:before {
    content: @icon-sign-2;
  }
}
.icon-sign-1 {
  &:before {
    content: @icon-sign-1;
  }
}
.icon-zvon-logo-small {
  &:before {
    content: @icon-zvon-logo-small;
  }
}
.icon-zvon-logo {
  &:before {
    content: @icon-zvon-logo;
  }
}
.icon-view {
  &:before {
    content: @icon-view;
  }
}
.icon-bath {
  &:before {
    content: @icon-bath;
  }
}
.icon-zoom {
  &:before {
    content: @icon-zoom;
  }
}
.icon-menu {
  &:before {
    content: @icon-menu;
  }
}
.icon-chevron-right-light {
  &:before {
    content: @icon-chevron-right-light;
  }
}
.icon-chevron-left-light {
  &:before {
    content: @icon-chevron-left-light;
  }
}
.icon-bell {
  &:before {
    content: @icon-bell;
  }
}
.icon-calendar {
  &:before {
    content: @icon-calendar;
  }
}
.icon-chevron-left {
  &:before {
    content: @icon-chevron-left;
  }
}
.icon-chevron-right {
  &:before {
    content: @icon-chevron-right;
  }
}
.icon-chevron-up {
  &:before {
    content: @icon-chevron-up;
  }
}
.icon-chevron-down {
  &:before {
    content: @icon-chevron-down;
  }
}
.icon-map {
  &:before {
    content: @icon-map;
  }
}
.icon-mail {
  &:before {
    content: @icon-mail;
  }
}
.icon-phone {
  &:before {
    content: @icon-phone;
  }
}
.icon-arrow-2-top {
  &:before {
    content: @icon-arrow-2-top;
  }
}
.icon-arrow-2-down {
  &:before {
    content: @icon-arrow-2-down;
  }
}
.icon-arrow-2-right {
  &:before {
    content: @icon-arrow-2-right;
  }
}
.icon-arrow-2-left {
  &:before {
    content: @icon-arrow-2-left;
  }
}
.icon-arrow-right {
  &:before {
    content: @icon-arrow-right;
  }
}
.icon-arrow-left {
  &:before {
    content: @icon-arrow-left;
  }
}
.icon-arrow-down {
  &:before {
    content: @icon-arrow-down;
  }
}
.icon-arrow-up {
  &:before {
    content: @icon-arrow-up;
  }
}
.icon-close {
  &:before {
    content: @icon-close;
  }
}



.icon-flag-cs {
  .sprite(@flag-cz);
  display: block;
}

.icon-flag-en {
  .sprite(@flag-en);
  display: block;
}

.icon-flag-de {
  .sprite(@flag-de);
  display: block;
}

.icon-flag-ru {
  .sprite(@flag-ru);
  display: block;
}

.icon-flag-es {
  .sprite(@flag-es);
  display: block;
}

.icon-flag-fr {
  .sprite(@flag-fr);
  display: block;
}
