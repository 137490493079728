#main-footer {
  .sub-footer-new {
    background: @footer-background-1;
    padding: 56px 0;
    @media @768 {
      padding: 12px 0;
    }
    .container {
      > .row {
        > .col {
          @media @768 {
            padding: 12px 0;
            width: 100%;
            float: none;
            text-align: center;
          }
          @media @480 {
            .col {
              padding: 6px 0;
              width: 100%;
              float: none;
            }
          }
        }
      }
    }
    .elm_logo {
      position: relative;
      width: 146px;

      @media @1024 {
        width: 120px;
      }

      @media @768 {
        margin: 0 auto;
      }

      &:before {
        content: "";
        display: block;
        padding-bottom: 50/73*100%;
      }
      > img {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
      }
    }
    .elm_list {
      .elm_title {
        display: block;
        margin-bottom: 16px;
        color: @main-color-4;
        letter-spacing: 0.02em;
        text-transform: uppercase;
        font-size: @font-size-15;
      }
      ul {
        padding: 0;
        margin: 0;
        li {
          line-height: 1.75;
          font-size: @font-size-15;
          font-weight: @font-light;
          list-style: none;
          a, & {
            color: fade(@footer-color-1,70%);
          }
          a {
            &:hover {
              text-decoration: underline;
              color: fade(@footer-color-1,100%);
            }
          }
        }
      }
    }
  }
  .sub-footer {
    background: @footer-background-1;
    html.zanzibar &{
      background: @footer-background;
    }
    padding: 56px 0;
    box-sizing: border-box;
    @media @768 {
      padding: 25px 0 45px;
    }
    .heading {
      font-size: @font-size-30;
      color: @footer-color-1;
      font-weight: @font-normal;
      margin: 0 0 20px;
      html.zanzibar &{
        color: #fff;
        font-family: @font-fertigo;
      }
    }
    p {
      font-size: @font-size-17;
      color: tint(@main-color-1, 50%);
      line-height: 23px;
      letter-spacing: -0.010em;
      margin: 0 0 30px;
    }
    .list-wrapper {
      @media @768 {
        margin: 0 -40px;
      }
      @media @360 {
        margin: 0 -15px;
      }

      .title {
        vertical-align: top;
      }

      ul {
        list-style: none;
        padding: 0 0 0 8px;
        margin: 0;
        display: inline-block;
      }
    }
    .list {
      padding: 0 40px;
      box-sizing: border-box;

      @media @min959 {
        &:first-child{
        padding-left: 0;
      }
      }
      html.zanzibar &{
        padding: 0;
        margin:0;

        @media  @1024{
          display: block;
          float: none;
          padding-left: 0;
        }



        &:nth-child(2){
          padding-left: 25px;
          @media  @1024{
            display: block;
            padding-left: 0;
          }
          @media @420 {
            padding: 0;
          }
        }
      }

      @media @360 {
        padding: 0 15px;
      }
      .title {
        font-size: @font-size-17;
        color: @main-color-4;
      }
      a {
        font-size: @font-size-17;
        color: @main-color-4!important;
        letter-spacing: -0.010em;
        margin-bottom: 2px;
        &:hover {
          color: #fff;
        }
      }
    }
    .map {
      position: relative;
      //display: table;
      .view-map {
        font-size: @font-size-17;
        text-decoration: underline;
        font-weight: @font-bold;
        &:hover {
          color: #fff;
        }
        @media @360 {
          position: relative;
          top: -18px;
        }
      }
      .map-text {
        width: 240px;
        p {
          @media @360 {
            margin: 0 0 50px;
          }
        }
        a{
          color: @main-color-4;
          html.zanzibar &{
              color: @main-color-4!important;
          }

        }
      }
      .map-wrapper {
        @media @1024 {
          position: absolute;
          right: 10px;
        }
        @media @360 {
          bottom: 15px;
          img {
            width: 100px;
          }
        }
        @media @320 {
          img {
            width: 85px;
          }
        }
        .footer-map {
          position: relative;
          overflow: hidden;
          border-radius: 50%;
          transform: translateZ(0);
          img {
            transition: transform 500ms ease 0s;
          }
          &:hover {
            img {
              transform: scale(1.1);
            }
          }
        }
      }
    }

    .social {
      @media @768 {
        margin: 0 -15px;
      }
      @media @360 {
        margin: 0 -10px;
      }
      @media @320 {
        margin: 0 -8px;
      }
      .icon {
        font-size: 36px;
        vertical-align: middle;
        margin: 15px;
        html.zanzibar &{
          color: @footer-color-1;
        }
        @media @360 {
          margin: 10px;
        }
        @media @320 {
          margin: 8px;
        }
        &:hover {
          color: #fff;
          html.zanzibar & {
            color: @main-color-4;
          }
        }
        &:before{
          color: @main-color-4;
        }
      }
    }
    .tripadvisor {
      a {
        color: @main-color-4;
        html.zanzibar &{
          color: @footer-color-1;
        }
        display: table;
        margin: 15px 0 0 15px;
        @media @768 {
          margin-left: 0;
        }
        &:hover {
          .icon, .text {
            color: #fff;
            html.zanzibar &{
              color: @main-color-4;
            }
          }
        }
        q{
          color: @main-color-4;
        }
      }
      .icon {
        font-size: @font-size-40;
        transition: @transition-color;
      }
      .text {
        font-size: @font-size-25;
        letter-spacing: -0.010em;
        color: @main-color-4;
        font-family: @font-family-libre;
        font-style: italic;
        margin: 5px 0 0 30px;
        transition: @transition-color;
        html.zanzibar &{
          color: #fff;
        }
      }
    }
    .row {
      display: table;
      width: 100%;
      border-bottom: 1px solid @main-color-12;
      padding-bottom: 60px;
      &.last {
        border-bottom: 0;
        padding-bottom: 0;
        margin-bottom: 0;
        border-top: 1px solid #666;
        padding-top: 60px;
        html.zanzibar &{
        border:none;
        }
      }
    }
    .col {
      padding: 0 42px;
      box-sizing: border-box;
      border-right: 1px solid @main-color-12;
      min-height: 220px;
      &:first-of-type {
        padding-left: 0;
      }
      &:last-of-type {
        padding-right: 0;
      }
      &.last {
        border-right: 0;
        border-left: 1px solid #666;
        html.zanzibar &{
          border:none;
        }
        @media @768 {
          border-left: 0;
          display: table;
        }
      }
    }
  }
  .footer {
    background: @footer-background-2;
    html.zanzibar &{
      background: @main-color-6;
    }
    padding: 22px 0 20px;
    p {
      font-size: @font-size-15;
      margin: 0;
      color: @footer-color-3;
    }
    a {
      color: @footer-color-3;
      &:hover {
        text-decoration: underline;
      }
    }
    .container {
      position: relative;
    }
    .nl-logo {
      .created {
        font-size: @font-size-15;
        margin: 0 20px 0 0;
        color: @footer-color-3;
        @media @360 {
          display: none;
        }
      }
      .footer-logo {
        transition: opacity 0.5s ease 0s;
        img {
          @media @360 {
            width: 58px;
          }
        }
        &:hover, &.active {
          opacity: 0.8;
        }
      }
      #copyrightspopup {
        display: none;
        bottom: 50px;
        position: absolute;
        right: 0;
        left: auto;
        margin: auto;
        z-index: 10000;
        padding: 0 20px;
        box-sizing: border-box;
        .inner {
          background: none repeat scroll 0 0 #FFF;
          border: 1px solid #DDD;
          border-radius: 5px;
          line-height: 1;
          color: #000;
          font-size: 15px;
          padding: 12px;
          text-align: center;
          width: auto;
          display: table;
          margin: 0 auto;
        }
        a {
          padding: 0;
          color: #575757;
          text-decoration: underline;
        }
        a:hover {
          color: @link-color;
        }
      }
    }
  }
}
