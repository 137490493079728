#page {
  background: @page-background;

  @media @768 {
    .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12 {
      width: 100%;
      float: none;
    }
  }
  #hp-visual {
    &.booking {
      .hp-visual-inner {
        min-height: 220px !important;
        max-height: 220px !important;
      }
      .hp-visual-content {
        display: none;
      }
    }
    position: relative;
    animation: 1s ease-out 0s normal backwards 1 running fadeIn;
    @height: calc(~"90vh");
    @height-2: 600px;
    @height-3: 520px;
    @height-max: 455px;
    @height-small: 455px;
    @620h: ~"all and (max-height: 620px)";
    @690h: ~"all and (max-height: 585px)";
    @1980: ~"all and (min-width: 1980px)";
    &.sp {
      .hp-visual-inner {
        height: @height-small;
        overflow: hidden;
        @media @480 {
          height: @height-small!important;
        }
        .item {
          height: @height-small;
          @media @620h {
            height: @height-small;
          }
          @media @480 {
            height: @height-small!important;
          }
        }
      }
    }
    .hp-visual-inner {
      background: @main-color-3;
      margin: 0;
      overflow: hidden;
      height: @height-max;
      height: @height;
      @media @690h {
        height: @height-max !important;
      }
      @media @768 {
        height: @height-2 !important;
      }
      @media @480 {
        height: @height-3 !important;
      }
      .item {
        position: relative;
        overflow: hidden;
        width: inherit;
        height: @height-max;
        height: @height;
        &.lazy {
          display: none;
        }
        &.slick-slide {
          display: block;
        }
        @media @690h {
          height: @height-max !important;
        }
        @media @768 {
          height: @height-2 !important;
        }
        @media @480 {
          height: @height-3 !important;
        }
        img {
          .image-center;
          @media @1980 {
            width: 100%;
          }
          @media @480 {
            width: 100%;
          }
          @media @360 {
            width: auto;
          }
          @media @320 {
            width: 100%;
            height: 100%;
          }
        }
      }
      .slick-prev {
        left: 30px;
        border-radius: 50%;
        background-color: transparent;
        transition: @transition-background;
        @media @360 {
          display: none !important;
        }
        &:hover {
          background-color: rgba(255,255,255,0.3);
        }
      }
      .slick-next {
        right: 30px;
        border-radius: 50%;
        background-color: transparent;
        transition: @transition-background;
        @media @360 {
          display: none !important;
        }
        &:hover {
          background-color: rgba(255,255,255,0.3);
        }
      }
      .slick-prev:before, .slick-next:before {
        width: 38px;
        height: 38px;
        display: inline-block;
        line-height: 38px;
        border-radius: 50%;
        font-size: 20px;
        border: 1px solid #fff;
      }
    }
    .hp-visual-content {
      position: absolute;
      width: 100%;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1000;
      .main-offer {
        position: absolute;
        left: 0;
        top: -150%;
        transition: top 0.5s ease 0s;
        animation: 1s ease-out 0s normal backwards 1 running slideInLeft;
        @media all and (max-height: 768px) {
          top: -100%;
        }
        @media @768 {
          position: static;
          animation: none;
        }
        a {
          min-width: 539px;
          height: 66px;
          background: @main-color-6;
          background: fade(@main-color-6, 80%);
          padding: 20px 20px 20px 85px;
          box-sizing: border-box;
          position: relative;
          border-radius: 0 40px 40px 0;
          transition: @transition-background;
          &:hover, &.active {
            background: @main-color-6;
          }
          &:hover {
            .icon {
              transform: rotate(-1.5rad);
              @media @768 {
                transform: none;
              }
            }
          }
          @media @768 {
            border-radius: 0;
            min-width: 100%;
            height: 62px;
            display: table;
            padding: 10px 85px 10px 20px;
          }
          @media @360 {
            height: 66px;
          }
        }
        .icon {
          position: absolute;
          right: 0;
          font-size: 36px;
          color: @main-color-6;
          background: @main-color-4;
          padding: 15px;
          box-sizing: border-box;
          top: 0;
          border-radius: 50%;
          transition: transform 0.5s ease 0s;
          @media @768 {
            height: 62px;
            border-radius: 0;
            font-size: 32px;
            transition: none;
          }
          @media @360 {
            height: 66px;
          }
          html.zanzibar &{
            padding: 15px 20px 15px 17px;
            &:before{
              content: @icon-packa;
            }
          }
        }
        .text {
          font-size: @font-size-20;
          letter-spacing: 0.015em;
          color: #fff;
          @media @768 {
            .table-cell;
          }
        }
      }
      .main-availability {
        background: @main-color-6;
        background: fade(@main-color-6, 50%);
        padding: 40px 0;
        box-sizing: border-box;
        @media @768 {
          padding: 10px 0;
        }
        .f-availability {
          padding: 0 30px;
          box-sizing: border-box;
          .col {
            padding: 0 30px;
            box-sizing: border-box;
            @media @1024 {
              padding: 0 10px;
            }
            @media @768 {
              padding: 10px;
            }
            > .inner {
              position: relative;
              > div {
                &:hover {
                  .icon {
                    color: @main-color-1;
                  }
                }
                .icon {
                  padding: 14px;
                  font-size: 16px;
                  box-sizing: border-box;
                  position: absolute;
                  top: 0;
                  bottom: 0;
                  right: 0;
                  color: @main-color-6;
                  transition: @transition-color;
                  cursor: pointer;
                }
                .icon-code {
                  cursor: default;
                  font-weight: bold;
                  font-size: 25px;
                  top: -6px;
                  &:before {
                    content: "#";
                  }
                }
              }
            }
          }
          .f-input {
            border: 0;
            height: 46px;
            line-height: 46px;
            box-sizing: border-box;
            border-radius: 2px;
            letter-spacing: 0.006em;
            text-transform: uppercase;
            font-weight: @font-medium;
            text-align: left;
            width: 100%;
            padding: 2px 48px 2px 10px;
            transition: border 0.2s ease 0s;
          }
          .date {
            cursor: pointer;
          }
          .btn-type-1 {
            height: 46px;
            width: 100%;
          }
          .link {
            color: #fff;
            border-bottom: 1px solid #fff;
            margin-top: 4px;
            font-size: 13px;
            @media @768 {
              position: relative;
              margin-top: 8px;
              top: 4px;
            }
          }
        }
        .availability-info {
          position: absolute;
          right: 0;
          left: 0;
          top: -29px;
          box-sizing: border-box;
          font-size: @font-size-15;
          color: @main-color-1;
          text-align: center;
          min-height: 24px;
          @media @480 {
            top: 0;
          }
          .bad {
            padding: 5px 0;
            background: #BA0000;
            background: rgba(180, 70, 70, 0.7);
            color: #fff;
          }
          .good {
            padding: 5px 0;
            background: #0D8D00;
            background: rgba(13, 141, 0, 0.7);
            color: #fff;
          }
        }
      }
    }
  }
  #hp-about {
    padding: 0 0 46px;
    position: relative;
    background: @main-color-6;
    html.zanzibar &{
      background: @page-background;
    }
    color: @main-color-11;
    p {
      font-size: @font-size-19;
      text-align: center;
      line-height: 27px;
      margin: 0;
    }
    img {
      max-width: 260px;
      height: auto;
      html.zanzibar &{
        max-height: 50px;
        width: auto;
        height: 60px;
        display: block;
        margin:0 auto;
      }
    }
    .heading-type-1 {
      position: relative;
      z-index: 1;
      .heading {
        color: @main-color-7;
        html.zanzibar &{
          color: @main-color-6-footer;
        }
      }
    }
    .hp-about-wrapper {
      position: relative;
      z-index: 1;
      p{
        html.zanzibar &{
          text-align: justify;
          color: @main-color-6;
        }
      }
      hr{
        html.zanzibar &{
          display: block;
          height: 1px;
          border-bottom: 2px solid @main-color-4;
          border-top: none!important;
          width: 20%;
          margin: 0 auto;
        }
      }
      img{
        html.zanzibar &{
          max-height: 50px;
        }
      }
      ul {
        &:extend(#page .sp-text .sp-text-inner ul all);
        list-style-type: none !important;
      }
    }
    .hp-about-background {
      position: absolute;
      top: 20px;
      left: 0;
      right: 0;
      z-index: 0;
      display: none;
    }
  }
  .hp-slides {
    position: relative;
    @media all and (min-width: 1980px) {
      .slick-slide img {
        width: 100%;
      }
    }
    &.center {
     .hp-slides-inner {
       .item {
         .item-content {
           text-align: center;
           p {
             margin: 0 auto 45px;
           }
           .btn-type-2 {
             margin: 0 auto;
             &:hover{
               html.zanzibar &{
                   background: @main-color-4;
               }
             }
           }
         }
       }
     }
    }
    .container {
      @media @1024 {
        width: 100%;
      }
      html.zanzibar &{
        padding-bottom: 25px;
      }
    }
    .hp-slides-wrapper {
      width: inherit;
      position: relative;
    }
    .hp-slides-inner {
      background: #ccc;
      html.zanzibar &{
        background: @main-color-6;
      }
      margin: 0;
      overflow: hidden;
      .slick-prev {
        left: 30px;
        @media @360 {
          display: none !important;
        }
      }
      .slick-next {
        right: 30px;
        @media @360 {
          display: none !important;
        }
      }
      .item {
        position: relative;
        margin-bottom: 6px;
        html.zanzibar &{
          margin-bottom: 3px;
        }
        .item-image {
          overflow: hidden;
          min-height: 423px;
          max-height: 423px;
          .image-background(fade(#333, 35%));
          @media @768 {
            position: absolute;
            width: 100%;
            height: 100%;
            min-height: 0;
            max-height: none;
            picture {
              display: block;
              width: 100%;
            }
            img {
              .image-center;
              height: 100%;
            }
          }
          @media @480 {
            img {
              width: 100%;
              height: auto;
            }
          }
        }
        .item-content {
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: 3;
          padding: 0;
          box-sizing: border-box;
          display: table;
          width: 100%;
          height: 423px;
          @media @768 {
            position: relative;
            height: auto;
            padding: 30px 0;
          }
          .item-inner {
            box-sizing: border-box;
            @media all and (max-width: 1439px) {
              padding: 0 80px;
            }
            @media @768 {
              padding: 0;
            }
            @media @360 {
              padding: 0;
            }
          }
          .heading {
            font-size: @font-size-40;
            line-height: 28px;
            font-family: @font-fertigo;
            text-transform: uppercase;
            text-shadow: 0 0 7px fade(@slides-shadow, 25%);
            color: @slides-color;
            font-weight: @font-light;
            margin: 0 0 25px;
          }
          p {
            font-size: @font-size-19;
            line-height: 27px;
            text-shadow: 0 0 7px fade(@slides-shadow, 25%);
            color: @slides-color;
            margin: 0 0 45px;
            width: 60%;
            @media @1024 {
              width: 60%;
            }
            @media @768 {
              width: 100%;
            }
            @media @360 {
              line-height: 20px;
              text-align: justify;
            }
          }
          .btn-type-1 {
            min-width: 175px;
            margin-bottom: 10px;
            margin-right: 25px;
          }
          .btn-type-2 {
            text-align: center;
            display: table;
            min-width: 175px;
            &:hover{
              html.zanzibar &{
                background: @main-color-4!important;
              }
            }
          }
        }
      }
    }
    .hp-slides-content {
      position: absolute;
      right: 0;
      left: 0;
      top: 0;
      z-index: 3;
      display: none;
      .count {
        position: relative;
        top: 30px;
        right: 30px;
        @media @768 {
          top: 10px;
          right: 0;
          font-size: 0.8em;
        }
        .text {
          font-size: @font-size-32;
          text-shadow: 0 0 7px fade(@slides-shadow, 25%);
          color: @slides-color;
          font-family: @font-family-libre;
        }
        .min {
          font-size: @font-size-60;
          text-shadow: 0 0 7px fade(@slides-shadow, 25%);
          color: @slides-color;
          font-family: @font-family-lato;
        }
        .max {
          font-family: @font-family-lato;
        }
      }
    }
  }
  .hp-offers {
    &.is-slide {
      .hp-offers-wrapper {
        margin: 0 auto 25px;
        width: @container - (2*@container-padding);
        @media all and (max-width: 1299px) {
          width: @container - (8*@container-padding);
        }
        @media @1024 {
          width: @container-1 - (8*@container-1-padding);
        }
        @media @768 {
          width: @container-2 - (2*@container-2-padding);
        }
        @media @480 {
          width: 100%;
        }
        .slick-prev {
          left: -40px;
          &:before {
            color: @main-color-4 !important;
            html.zanzibar &{
              color:@main-color-3!important;
            }
          }
        }
        .slick-next {
          right: -40px;
          &:before {
            color: @main-color-4 !important;
            html.zanzibar &{
              color:@main-color-3!important;
            }
          }
        }
      }
      .hp-offers-inner {
        &.row {
          display: block;
        }
        .col {
          width: 100%;
        }
      }
    }
    .hp-offers-wrapper {
      margin: 0 auto 25px;
    }
    .hp-offers-inner {
      &.row {
        display: table;
        width: 100%;
        margin: 40px -15px 15px;
        @media @480 {
          margin: 40px 0;
        }
        @media @360 {
          margin: 15px 0;
        }
      }
      .col {
        padding: 15px;
        box-sizing: border-box;
        width: 33.33%;
        @media @768 {
          width: 50%;
        }
        @media @480 {
          width: 100%;
          float: none;
          padding: 15px 0;
        }
      }
      .item {
        box-shadow: 0 0 3px 0 #999;

        .item-title {
          position: relative;
          .item-heading {
            position: absolute;
            bottom: 0;
            left: 0;
            z-index: 4;
            .heading {
              font-size: @font-size-24;
              color: #fff;
              font-weight: @font-light;
              margin: 0;
              padding: 20px;
              box-sizing: border-box;
              @media @1024 {
                font-size: @font-size-24;
              }
              @media @480 {
                font-size: 2em;
              }
              html.zanzibar &{
                position: relative;
                padding-top: 40px;
                padding-left: 20px;
                &:before{
                  position: absolute;
                  content: '';
                  width: 50px;
                  height: 2px;
                  background: @main-color-4;
                  top:25px;
                }
              }
            }
          }
          .item-image {
            position: relative;
            overflow: hidden;
            width: inherit;
            height: 315px;
            .image-background;
            &:after {
              background: transparent;
              background: -moz-linear-gradient(top,  rgba(51,51,51,0.65) 0%, rgba(26,26,26,0.1) 50%, rgba(0,0,0,0) 100%); /* FF3.6+ */
              background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(51,51,51,0.65)), color-stop(50%,rgba(26,26,26,0.1)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
              background: -webkit-linear-gradient(top,  rgba(51,51,51,0.65) 0%,rgba(26,26,26,0.1) 50%,rgba(0,0,0,0) 100%); /* Chrome10+,Safari5.1+ */
              background: -o-linear-gradient(top,  rgba(51,51,51,0.65) 0%,rgba(26,26,26,0.1) 50%,rgba(0,0,0,0) 100%); /* Opera 11.10+ */
              background: -ms-linear-gradient(top,  rgba(51,51,51,0.65) 0%,rgba(26,26,26,0.1) 50%,rgba(0,0,0,0) 100%); /* IE10+ */
              background: linear-gradient(to bottom,  rgba(51,51,51,0.65) 0%,rgba(26,26,26,0.1) 50%,rgba(0,0,0,0) 100%); /* W3C */
            }
            @media @1024 {
              height: 200px;
            }
            img {
              -webkit-transition: all 0.9s;
              -moz-transition: all 0.9s;
              -ms-transition: all 0.9s;
              -o-transition: all 0.9s;
              transition: all 0.9s;
              .image-center;
              width: 100%;
            }
          }
        }
        .item-text {
          display: table;
          width: 100%;
          background: @main-color-6;
          padding: 20px;
          box-sizing: border-box;
          html.zanzibar &{
            vertical-align: middle;
            background: @main-color-6-footer;
            -webkit-transition: background 0.8s;
            -moz-transition: background 0.8s;
            -ms-transition: background 0.8s;
            -o-transition: background 0.8s;
            transition: background 0.8s;
          }
          .item-price {
            position: relative;
            margin-top: 0;
            @media @768 {
              width: 50%;
              float: left;
            }
            .price {
              font-size: @font-size-26;
              color: @main-color-4;
              font-weight: @font-bold;
              line-height: 1;
              html.zanzibar & {
                font-size: @font-size-30;
                color: @page-background;
                max-width: 180px;
                font-weight: normal;
              }
            }
            .text {
              display: block;
              font-size: @font-size-14;
              color: @main-color-1;
            }
          }
          .item-btn {
            @media @768 {
              width: 50%;
              float: left;
            }
            html.zanzibar & {
              display: table-cell;
              margin-top: 6px;
            }
            .btn-type-1 {
              width: 100%;
              border: 1px solid @main-color-4;
              background-color: transparent;
              color: @main-color-4 !important;
              @media @1024 {
                padding: 10px 0;
              }
              &:hover, &.active {
                background: @main-color-4;
                color: #fff !important;
              }
            }
          }
        }

        &:hover{
        .item-title{
          .item-image{
            img{
              transform: scale(1.1);
            }
          }
        }
        }
      }
    }
    .btn-type-2 {
      border: 1px solid @main-color-1;
      html.zanzibar & {
        border:1px solid @main-color-4;
      }
      color: @main-color-1 !important;
      &:hover, &.active {
        background: @main-color-1;
        color: #fff !important;
        html.zanzibar & {
          background: @main-color-4!important;
        }
      }
    }
  }
  #hp-gallery {
    .container {
      padding: 0;
      .heading-type-1 {
        .heading {
          html.zanzibar & {
            position: relative;
            color: @main-color-6-footer;
            &:before{
              position: absolute;
              content: '';
              height: 2px;
              bottom:0;
              background: @main-color-4;
              width:50px;
              display:inline-block;
              margin: 0 auto;
              text-align: center;
              left: 0;
              right: 0;
              bottom:-12px;
            }
          }
        }
      }
      .sub-heading {
        border-top: 1px solid fade(@main-color-6,10%);
        border-bottom: 1px solid fade(@main-color-6,10%);
        padding: 18px;
        margin-top: 48px;
        margin-bottom: 48px;
        html.zanzibar &{
          @media @768 {
            padding: 0;
            margin: 15px auto 0;
            display: block;
          }
          margin-top: 30px;
          color:tint(@link-color,40%);
          a{
            margin: 0 10px;
          }
          @media @1024 {
            font-size: 15px!important;
          }
          @media @768{
            a{
              font-size: 20px !important;
              margin: 0 10px 10px;
              width: 100%;
              display: block;
              text-align: center;
            }

          }
          &:hover{
            color: @main-color-6-footer!important;
          }
          .active{
            color:@main-color-6-footer;
          }
        }
        a {
          text-transform: uppercase;
          font-size: @font-size-14;
          font-weight: @font-bold;
        }
      }
    }
    .sub-heading-wrapper {
      width: auto;
      margin: 0 auto 15px;
      display: table;
      &.slide {
        width: 550px;
        display: block;
        margin: 0 auto;
        @media @480 {
          width: auto;
          margin: 0 auto;
          display: table;
        }
        .slick-next, .slick-prev {
          top: 14px;
        }
        .slick-next::before, .slick-prev::before {
          color: #333;
          font-size: 16px;
        }
      }
      .sub-heading {
        display: block;
        a {
          display: block;
          float: left;
          @media @480 {
            display: block;
            float: none;
          }
        }
      }
    }
    .hp-gallery-wrapper {
      width: inherit;
      min-height: 521px;
      margin: 20px auto 60px;
      background: transparent url("http://cdnjs.cloudflare.com/ajax/libs/slick-carousel/1.5.8/ajax-loader.gif") no-repeat scroll center 40%;
      @media @480 {
        min-height: 0;
      }
      .hp-gallery-inner {
        background: #fff;
        width: inherit;
        opacity: 1;
        transition: max-height 0.5s ease 0s, min-height 0.5s ease 0s, opacity 0.5s ease 0s;
        &.not-visible {
          opacity: 0;
        }
        .wrapper-1 {
          width: inherit;
        }
        .wrapper-2 {
          width: 670px;
          @media @768 {
            width: inherit;
          }
          margin: 0 auto;
        }
        @media @768 {
          width: 620px;
        }
        @media @480 {
          width: 480px - 2*@container-3-padding;
          width: calc(~"100vw" - 2*@container-3-padding);
          margin: 0 auto;
        }
        @media @360 {
          width: 360px - 2*@container-4-padding;
          width: calc(~"100vw" - 2*@container-4-padding);
          margin: 20px auto;
        }
        @media @320 {
          width: 320px - 2*@container-5-padding;
          width: calc(~"100vw" - 2*@container-5-padding);
        }
        .gallery-preview {
          position: relative;
          &:before {
            content: normal;
            @media @768 {
              content: normal;
            }
            display: block;
            width: 25%;
            @media @1024 {
              width: 20%;
            }
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background: -moz-linear-gradient(left,  rgba(255,255,255,1) 0%, rgba(255,255,255,0) 75%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, right top, color-stop(0%,rgba(255,255,255,1)), color-stop(75%,rgba(255,255,255,0))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 75%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 75%); /* Opera 11.10+ */
            background: -ms-linear-gradient(left,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 75%); /* IE10+ */
            background: linear-gradient(to right,  rgba(255,255,255,1) 0%,rgba(255,255,255,0) 75%); /* W3C */
            z-index: 1;
          }
          &:after {
            content: normal;
            @media @768 {
              content: normal;
            }
            display: block;
            width: 25%;
            @media @1024 {
              width: 20%;
            }
            height: 100%;
            position: absolute;
            top: 0;
            right: 0;
            background: -moz-linear-gradient(left,  rgba(255,255,255,0) 25%, rgba(255,255,255,1) 100%); /* FF3.6+ */
            background: -webkit-gradient(linear, left top, right top, color-stop(25%,rgba(255,255,255,0)), color-stop(100%,rgba(255,255,255,1))); /* Chrome,Safari4+ */
            background: -webkit-linear-gradient(left,  rgba(255,255,255,0) 25%,rgba(255,255,255,1) 100%); /* Chrome10+,Safari5.1+ */
            background: -o-linear-gradient(left,  rgba(255,255,255,0) 25%,rgba(255,255,255,1) 100%); /* Opera 11.10+ */
            background: -ms-linear-gradient(left,  rgba(255,255,255,0) 25%,rgba(255,255,255,1) 100%); /* IE10+ */
            background: linear-gradient(to right,  rgba(255,255,255,0) 25%,rgba(255,255,255,1) 100%); /* W3C */
            z-index: 1;
          }
          &.one {
            text-align: center;
            .item {
              transform: scale(1.05);
              display: none;
              &:first-of-type {
                display: inline-block;
              }
              @media @480 {
                img {
                  width: 100%;
                }
              }
            }
          }
          .slick-next {
            right: 50px;
            padding: 20px;
            margin-top: -40px;
            margin-right: -40px;
            z-index: 6;
            &:before {
              color: #666 !important;
              html.zanzibar &{
                color:@slides-color!important;
              }
            }
            @media @768 {
              &:before {
                color: #fff !important;
                html.zanzibar &{
                  color:@slides-color!important;
                }
              }
            }
          }
          .slick-prev {
            left: 50px;
            padding: 20px;
            margin-top: -40px;
            margin-left: -40px;
            z-index: 6;
            &:before {
              color: #666 !important;
              html.zanzibar &{
                color:@slides-color!important;
              }
            }
            @media @768 {
              &:before {
                color: #fff !important;
                html.zanzibar &{
                  color:@slides-color!important;
                }
              }
            }
          }
          .item {
            transform: scale(0.85);
            @media @768 {
              transform: none;
              margin: 0 10px;
            }
            padding: 10px 0;
            transition: opacity 0.5s ease 0s, transform 0.5s ease 0s;
            position: relative;
            z-index: 6;
            img {
              box-shadow: 0 0 10px 0 #999;
              margin: 0 auto;
              transition: transform 0.5s ease 0s;
              @media @360 {
                height: 225px;
                box-shadow: none;
              }
            }
            &:hover {
              opacity: 0.8;
              html.zanzibar & {
                .item img {
                  position: relative;
                  &:before {
                    position: absolute;
                    content: '';
                    width: 100%;
                    height: 100%;
                    display: block;
                    background: rgba(36, 80, 240, 0.65);
                    top: 0;
                    left: 0;
                    right: 0;
                    bottom: 0;
                  }
                }
              }
            }
          }
          .slick-center {
            transform: scale(1.05);
            @media @768 {
              transform: none;
            }
          }
        }
        .gallery-nav {
          background: transparent;
          padding: 0 20px;
          box-sizing: border-box;
          min-height: 104px;
          @media @480 {
            display: none;
          }
          &:before, &:after {
            content: " ";
            position: absolute;
            top: 0;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
          }
          &:before {
            left: -35px;
            border-color: transparent transparent transparent transparent;
            border-width: 52.5px 35px 52.5px 0;
          }
          &:after {
            right: -35px;
            border-color: transparent transparent transparent transparent;
            border-width: 52.5px 0 52.5px 35px;
          }
          &.one {
            display: none;
          }
          .item {
            padding: 15px 0;
            transition: opacity 0.5s ease 0s;
            cursor: pointer;
            &:hover {
              opacity: 0.8;
            }
            img {
              box-shadow: 0 0 10px 0 #999;
              width: 125px;
              margin: 0 auto;
              display: table;
            }
          }
          .slick-prev {
            left: 0;
            &:before {
              color: @main-color-3;
              font-size: 20px;
            }
          }
          .slick-next {
            right: 0;
            &:before {
              color: @main-color-3;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
  .sp-gallery {
    .sp-gallery-wrapper {
      display: table;
      width: 100%;
      //background: @main-color-8;
      padding: 40px 0 25px;
      //border-top: 1px solid @main-color-9;
      //border-bottom: 1px solid @main-color-9;
      @media @360 {
        padding: 15px 0 25px;
      }
    }
    .sp-gallery-inner {
      .row {
        margin: 0 -15px;
        .row-inner {
          margin: 0 auto;
        }
      }
      .col {
        padding: 15px;
        box-sizing: border-box;
      }
      .item {
        position: relative;
        display: table;
        width: 100%;
        box-shadow: 0 0 10px 0 #999;
        &:hover {
          .item-title {
            .icon {
              opacity: 1 !important;
            }
          }
          .item-image {
            .image-background(fade(#fff, 30%));
          }
        }
        .item-title {
          position: absolute;
          top: 0;
          right: 0;
          left: 0;
          padding: 15px 20px;
          box-sizing: border-box;
          z-index: 5;
          .heading {
            font-size: @font-size-30;
            letter-spacing: -0.010em;
            font-weight: @font-normal;
            color: #fff;
            margin: 0;
            .col-8;
          }
          .icon {
            color: #fff;
            opacity: 0.4;
            font-size: 30px;
            position: relative;
            top: 5px;
            transition: opacity 0.5s ease 0s;
          }
        }
        .item-image {
          position: relative;
          overflow: hidden;
          width: inherit;
          height: 334px;
          transition: all 0.5s ease 0s;
          @media @1024 {
            height: 250px;
          }
          .image-background(fade(@main-color, 40%));
          img {
            .image-center;
          }
        }
      }
    }
  }
  #sp-contact {
    .sp-contact-wrapper {
      border-top: 1px solid @main-color-9;
      html.zanzibar &{
        border: none!important;
      }
      border-bottom: 1px solid @main-color-9;
      padding: 50px 0;
      box-sizing: border-box;
      @media @m960 {
       padding: 0;
      }
    }
    .sp-contact-inner {
      .heading {
        font-size: @font-size-30;
        color: @main-color-1;
        font-weight: @font-normal;
        margin: 0 0 20px;
        html.zanzibar &{
          color: @main-color-6-footer;
        }
      }
      .form-inner {
        .heading {
          margin-left: 20px;
          @media @768 {
            margin-left: 0;
          }
        }
        .f-contact-form {
          width: 100%;
          background: tint(@main-color-8, 83%);
          border: 1px solid @main-color-9;
          padding: 20px;
          box-sizing: border-box;
          html.zanzibar &{
            background: #ddd;
          }
          .row {
            margin: 0 -5px;
          }
          .col {
            padding: 0 5px;
            box-sizing: border-box;
          }
          .f-input, .f-select, .f-text {
            width: 100%;
            height: 56px;
            padding: 15px;
            box-sizing: border-box;
            border: 1px solid @main-color-9;
            margin-bottom: 5px;
            font-size: @font-size-19;
            color: @main-color-6;
            &.error {
              border: 1px solid @main-color-4;
            }
          }
          .f-select {
            margin-bottom: 40px;
          }
          .select2 {
            margin-bottom: 40px;
            width: 100% !important;
            &.error {
              border: 1px solid @main-color-4;
            }
          }
          .select2-selection--single {
            border: 1px solid @main-color-9;
            height: 56px;
            .select2-selection__rendered {
              padding: 15px;
              font-size: @font-size-19;
            }
          }
          .select2-container--default .select2-selection--single .select2-selection__arrow b {
            display: none;
          }
          .select2-selection__arrow {
            .icomoon;
            height: auto;
            top: 20px;
            right: 15px;
            width: auto;
            color: @main-color-1;
            &:before {
              content: @icon-arrow-2-down;
            }
          }
          .icon-arrow-2-top {
            &:before {
              content: @icon-arrow-2-top !important;
            }
          }
          .f-text {
            height: 175px;
            margin-bottom: 30px;
          }
          .f-submit {
            @media @768 {
              float: none;
              margin: 0 auto;
            }
          }
          .info {
            font-size: @font-size-19;
            @media @768 {
              margin-bottom: 20px;
              text-align: center;
            }
            .bad {
              color: @main-color-4;
            }
            .good {
              color: #009900;
            }
          }
        }
      }
      .text-inner {
        @media @1024 {
          padding-left: 20px;
        }
        @media @768 {
          padding-left: 0;
          margin-bottom: 40px;
        }
        .icon-zvon-logo {
          font-size: 132px;
          color: @main-color-1;
        }
        p {
          font-size: @font-size-19;
          color: @main-color-6;
          line-height: 36px;
          margin: 40px 0;
        }
        .list {
          table {
            width: 100%;
          }
          a, td {
            color: @main-color-6;
            line-height: 28px;
          }
          a:hover {
            text-decoration: underline;
          }
          th {
            text-align: left;
          }
          td {
            font-size: @font-size-19;
            text-align: left;
            @media @768 {
              text-align: left;
            }
          }
        }
      }
    }
    .sp-contact-people {
      padding-bottom: 30px;
      .col {
        padding: 15px;
        box-sizing: border-box;
      }
      .item {
        img {
          display: table;
          margin: 0 auto 20px;
          border-radius: 50%;
        }
        .heading {
          font-size: @font-size-19;
          color: @main-color-6;
          line-height: 36px;
          font-weight: @font-normal;
          display: block;
          text-align: center;
          html.zanzibar &{
            color: @main-color-6-footer;
          }
        }
        .position {
          font-size: @font-size-17;
          color: @main-color-1;
          font-family: @font-family-libre;
          line-height: 22px;
          display: block;
          text-align: center;
        }
        .heading-line {
          .heading-line(@main-color-1, 1px);
          margin: 10px auto;
          html.zanzibar &{
          .heading-line(@main-color-4, 1px);
          }
        }
        a {
          font-size: @font-size-17;
          line-height: 22px;
          color: @main-color-6;
          display: block;
          text-align: center;
          &.tel{
            font-size: 20px;
            color: @main-color-4;
            padding-top: 6px;
            font-weight: normal;
          }
        }
      }
    }
    .sp-contact-directions {
      border-top: 1px solid @main-color-9;
      padding-bottom: 30px;
      .col {
        padding: 15px;
        box-sizing: border-box;
      }
      .item {
        .icon {
          font-size: 70px;
          display: block;
          text-align: center;
          margin: 30px 0;
          color: @main-color-1;
        }
        .heading {
          font-size: @font-size-24;
          font-family: @font-family-libre;
          color: @main-color-6;
          font-weight: @font-normal;
          display: block;
          text-align: center;
        }
        .heading-line {
          .heading-line(@main-color-1, 1px);
          margin: 10px auto;
          html.zanzibar &{
            .heading-line(@main-color-4, 1px);
          }
        }
        p {
          font-size: @font-size-17;
          line-height: 22px;
          text-align: center;
          margin: 10px 0;
        }
        a {
          font-size: @font-size-17;
          text-decoration: underline;
          line-height: 22px;
          color: @main-color-4;
          display: block;
          text-align: center;
        }
      }
    }
  }
  .sp-featuring {
    &.type-map {
      .item {
        .item-content {
          .bg {
            //.image-background(fade(@main-color-6, 45%));
          }
          .icon, .sub-heading {
            color: @main-color-4;
          }
          .heading {
            font-size: @font-size-35;
            @media @768 {
              font-size: @font-size-26;
            }
            @media @360 {
              font-size: @font-size-20;
            }
            @media @320 {
              font-size: @font-size-18;
              line-height: 30px;
            }
          }
        }
      }
    }
    .item {
      position: relative;
      max-height: 406px;
      overflow: hidden;
      .item-content {
        position: absolute !important;
        z-index: 4;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        margin: auto;
        cursor: pointer;
        display: table;
        width: 100%;
        height: 407px;
        background: fade(#333, 40%);
        &:hover {
          .btn-type-2 {
            background: #ccc;
            background: fade(#fff, 30%);
          }
        }
        .inner {
          position: relative;
          z-index: 6;
          padding: 80px 0;
          box-sizing: border-box;
        }
        .bg {
          position: static !important;
          //.image-background(fade(#000, 25%));
        }
        .icon {
          .hide !important;
          color: @main-color-1;
          font-size: 22px;
          display: block;
          text-align: center;
          -ms-transform: rotate(180deg); /* IE 9 */
          -webkit-transform: rotate(180deg); /* Safari */
          transform: rotate(180deg);
          text-shadow: 0 0 7px fade(@slides-shadow, 25%);
        }
        .sub-heading {
          color: @main-color-4;
          display: block;
          text-align: center;
          font-size: @font-size-22;
          margin: 10px auto 20px;
          text-shadow: 0 0 7px fade(@slides-shadow, 25%);
        }
        .heading {
          margin: 0 auto 30px;
          display: block;
          font-size: @font-size-55;
          font-family: @font-family-libre;
          font-weight: @font-light;
          line-height: 43px;
          color: #fff;
          text-align: center;
          text-shadow: 0 0 7px fade(@slides-shadow, 25%);
          html.zanzibar &{
            font-family: @font-fertigo;
          }
        }
        .btn-type-2 {
          width: auto;
          display: table;
          margin: 20px auto;
          min-width: 175px;
          &:hover{
            html.zanzibar &{
              background: @main-color-4!important;
            }
          }
        }
      }
      #google-map {
        height: 406px;
      }
      .item-image {
        width: inherit;
        height: 406px;
        img {
          .image-center;
        }
      }
    }
  }
  #sp-offers {
    .sp-offers-wrapper {
      //border-top: 1px solid @main-color-9;
      //border-bottom: 1px solid @main-color-9;
      padding: 50px 0;
      box-sizing: border-box;
      //background: @main-color-8;
      @media @360 {
        padding: 25px 0;
      }
    }
    .sp-offers-inner {
      .item {
        margin-bottom: 55px;
        box-shadow: 0 0 7px 0 fade(#000, 20%);
        @media @360 {
          margin-bottom: 25px;
        }
        .item-title {
          position: relative;
          cursor: pointer;
          &:hover, .active {
            .item-heading {
              .btn-type-2 {
                background: #ccc;
                background: fade(#fff, 30%);
              }
            }
            .item-image {
              .image-background(fade(#ccc, 20%));
            }
          }
          .item-heading {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            margin: auto;
            z-index: 4;
            display: table;
            width: 100%;
            height: 268px;
            .heading {
              margin: 0 auto 30px;
              display: block;
              font-size: @font-size-35;
              font-weight: @font-light;
              line-height: 43px;
              @media @360 {
                line-height: 32px;
              }
              color: #fff;
              text-align: center;
              text-shadow: 0 0 7px fade(@slides-shadow, 25%);
              padding: 0 20px;
              @media @768 {
                font-size: @font-size-30;
              }
            }
            .btn {
              width: auto;
              margin: 20px auto;
              min-width: 175px;
            }
          }
          .item-image {
            overflow: hidden;
            width: inherit;
            min-height: 268px;
            max-height: 268px;
            .image-background(fade(#333, 20%));
            img {
              .image-center;
            }
          }
        }
        .item-content {
          padding: 15px 30px;
          box-sizing: border-box;
          background: #fff;
          html.zanzibar &{
            background: @main-color-6-footer;
          }
          @media @360 {
            padding: 10px;
          }
          .item-text {
            padding: 15px;
            opacity: 0;
            transition: opacity 1s ease 0s;
            &.active {
              opacity: 1;
            }
            .col {
              margin-bottom: 25px;
            }
            .heading {
              display: block;
              font-size: @font-size-20;
              line-height: 31px;
              color: @main-color-1;
              font-weight: @font-normal;
              margin-bottom: 25px;
            }
            p {
              font-size: @font-size-20;
              line-height: 28px;
              color: @main-color-6;
              padding-right: 30px;
              margin: 0;
              @media @768 {
                padding: 0;
                text-align: justify;
              }
            }
            ul {
              .sp-text .sp-text-inner .ul;
              li {
                font-size: @font-size-17;
              }
            }
          }
          .item-info {
            .price {
              @media @768 {
                margin-bottom: 15px;
              }
              .text {
                font-size: @font-size-17;
                color: @main-color-6;
                html.zanzibar &{
                  color: #fff;
                }
              }
              strong {
                font-weight: @font-normal;
                font-size: @font-size-30;
                color: @main-color-4;
                margin: 0 10px;
                position: relative;
                top: 1px;
              }
            }
            .btn {
              min-width: 175px;
              @media @768 {
                float: none;
              }
            }
          }
        }
      }
    }
  }
  .sp-text {
    padding: 60px 0;
    .aside {
      .heading-line {
        .heading-line(@main-color-1, 1px);
        margin: 20px auto;
        html.zanzibar &{
          .heading-line(@main-color-4, 1px);
        }
      }
      .box {
        > .heading {
          display: block;
          font-size: @font-size-21;
          font-weight: @font-normal;
          color: @main-color-6;
          margin-bottom: 25px;
          html.zanzibar &{
            color: @main-color-6-footer;
          }
        }
        &.navigation {
          ul {
            margin: 0 0 20px;
            padding: 0;
            list-style: none;
            li {
              height: 42px;
              position: relative;
              &.active {
                .icon:before {
                  float: right;
                  .icomoon;
                  content: @icon-arrow-2-right;
                  color: @main-color-1;
                  transition: content 0.5s ease 0s;
                }
                &:after {
                  content: " ";
                  position: absolute;
                  top: 0;
                  right: -12px;
                  display: block;
                  width: 0;
                  height: 0;
                  border-style: solid;
                  border-width: 21px 0 21px 12px;
                  border-color: transparent transparent transparent @main-color-10;
                  html.zanzibar &{
                    border-color: transparent transparent transparent @main-color-6-footer;
                  }
                  @media @480 {
                    border-width: 20px 0 20px 12px;
                  }
                }
                a {
                  background: @main-color-10;
                  border: 1px solid @main-color-10;
                  color: #fff;
                  html.zanzibar &{
                    background: @main-color-6-footer;
                    border: 1px solid @main-color-6-footer;
                  }
                }
              }
              &:hover {
                a[href] {
                  background: @main-color-9;
                  html.zanzibar &{
                    background: @main-color-6-footer;
                    color: #fff!important;
                  }
                }
              }

            }
            a {
              display: block;
              padding: 10px;
              box-sizing: border-box;
              border: 1px solid @main-color-1;
              background: @main-color-8;
              html.zanzibar &{
                background: @main-color-6;

              }
              margin: 0 0 -1px;
              transition: @transition-background, @transition-color;
            }
          }
        }
        &.offers {
          .hp-offers-inner {
            .hp-offers .hp-offers-inner;
            &.row {
              margin: 0 - 15px;
            }
            .col {
              padding: 15px;
              width: 100%;
              @media @768 {
                width: 50%;
              }
              @media @480 {
                width: 100%;
              }
            }
            .item {
              .item-text {
                padding: 10px;
                .item-price {
                  .text {
                    position: absolute;
                    margin: 0;
                    left: 0;
                    top: -12px;
                    @media @1024 {
                      top: -4px;
                    }
                    @media @768 {
                      margin-left: 20px;
                      position: static;
                    }
                  }
                  .price {
                    position: relative;
                    top: 8px;
                    font-size: @font-size-25;
                    @media @1024 {
                      top: 20px;
                    }
                    @media @768 {
                      position: static;
                    }
                  }
                }
              }
              .item-title {
                .item-heading {
                  .heading {
                    font-size: @font-size-30;
                    html.zanzibar &{
                      color: @main-color-4!important;
                    }
                  }
                }
                .item-image {
                  .image-background(fade(#333, 30%));
                  height: 200px;
                }
              }
            }
          }
        }
        &.events {
          .events-inner {
            .col {
              margin-bottom: 40px;
              &:last-child {
                margin-bottom: 10px;
              }
            }
            .date {
              font-size: @font-size-17;
              color: @main-color-1;
              margin: 0 0 10px;
            }
            .heading {
              font-size: @font-size-23;
              font-weight: @font-light;
              color: @main-color-6;
              padding-right: 20px;
              box-sizing: border-box;
              html.zanzibar &{
                color: @main-color-6-footer;
              }
            }
            p {
              font-size: @font-size-15;
              color: @main-color-6;
              line-height: 20px;
              margin: 0 0 10px;
            }
            a {
              font-size: @font-size-17;
              color: @main-color-4;
              text-decoration: underline;
              &:hover {
                color: @main-color;
              }
            }
          }
        }
      }
    }
    .content {
      @media @768 {
        margin-top: 40px;
      }
    }
    .sp-text-wrapper {
      .heading {
        font-size: @font-size-45;
        font-family: @font-family-libre;
        font-weight: @font-light;
        line-height: 46px;
        color: @main-color-11;
        margin: 0 0 10px;
        html.zanzibar &{
          color: @main-color-6-footer;
          font-family: @font-fertigo;
        }
        @media @480 {
          text-align: center;
        }
      }
      .sub-heading {
        font-size: @font-size-30;
        font-family: @font-family-libre;
        font-weight: @font-normal;
        color: @main-color-6;
        text-align: center;
        margin: 30px 0 0;
        html.zanzibar &{
          color: @main-color-6-footer;
        }
        @media @1024 {
          margin: 5px 0 0;
        }
      }
      .heading-sign-1 {
        .heading-sign-1(@main-color-4);
        font-size: 22px;
      }
      .heading-line-big {
        .heading-line-big(@main-color-1, 1px);
        margin-bottom: 20px;
        html.zanzibar &{
          .heading-line-big(@main-color-4, 1px);
        }
      }
      .row {
        margin: 40px auto 20px;
      }
      img {
        width: 100%;
        padding-right: 48px;
        box-sizing: border-box;
        @media @768 {
          padding-right: 0;
          margin-bottom: 15px;
        }
      }
      p {
        font-size: @font-size-17;
        line-height: 24px;
        color: @main-color-6;
        text-align: center;
      }
      .btn {
        font-size: @font-size-17;
        display: table;
        margin: 0 auto 20px;
      }
    }
    .sp-text-inner {
      h1 {
        font-size: @font-size-45;
        font-family: @font-family-libre;
        font-weight: @font-normal;
        line-height: 46px;
        color: @main-color-6;
        margin: 0 0 10px;
      }
      h2 {
        font-size: @font-size-30;
        font-family: @font-family-libre;
        font-weight: @font-normal;
        color: @main-color-6;
        text-align: center;
        margin: 0 0 10px;
      }
      h3, h4 {
        font-size: @font-size-24;
        color: @main-color-1;
        font-weight: @font-normal;
        margin: 0 0 20px;
      }
      p {
        font-size: @font-size-19;
        line-height: 27px;
        color: @main-color-6;
        margin: 0;
        @media @480 {
          text-align: justify;
        }
      }
      blockquote {
        font-size: @font-size-19;
        line-height: 27px;
        color: @main-color-6;
      }
      .heading-line-big {
        .heading-line-big(@main-color-1, 1px);
        width: 70%;
      }
      .ul {
        margin: 0;
        padding: 0 0 0 45px;
        list-style: none;
        li {
          font-size: @font-size-19;
          color: @main-color-6;
          margin-bottom: 10px;
          text-indent: -18px;
          line-height: 26px;
          ul {
            padding: 10px 0 0 35px;
            li {
              font-size: 1em;
            }
          }
          &:before {
            .icomoon;
            content: @icon-bell;
            color: @main-color-1;
            font-size: 13px;
            position: relative;
            right: 25px;
            html.zanzibar & {
              content: @icon-packa;
            }
          }
        }
      }
      .table {
        width: 100%;
        max-width: 827px;
        margin: 0 auto;
        @media @360 {
          font-size: 0.8em;
        }
        th {
          background: @main-color-8;
          text-align: left;
        }
        th, td {
          border: 1px solid @main-color-1;
          padding: 10px;
          box-sizing: border-box;
          font-size: @font-size-17;
          line-height: 28px;
          color: @main-color-6;
          font-weight: @font-normal;
        }
      }
      table {
        .table;
      }
      ul {
        .ul;
      }
      img {
        max-width: 100%;
        height: auto;
      }
    }
  }
  .sp-breadcrumb {
    background: @breadcrumb-bg;
    html.zanzibar &{
      background: @main-color-6;
    }
    height: 40px;
    line-height: 28px;
    border-bottom: 1px solid @breadcrumb-border;
    padding: 5px 0 8px;
    box-sizing: border-box;
    color: #fff;
    .icon {
      color: @main-color-1;
      font-size: 12px;
      margin: 0 4px;
    }
    a {
      color: #fff;
      &[href]{
        color: @link-color;
        &:hover {
          color: #fff;
        }
      }
    }
  }
}

.detail-rooms {
  &.fixed {
    position: fixed;
    z-index: 1010;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    height: 100vh;
    display: block;
    margin: auto;
    overflow-y: scroll;
    overflow-x: hidden;
    .detail-close {
      display: block !important;
    }
    .detail-content {
      p {
        color: #fff !important;
      }
    }
    .detail-footer {
      .nav {
        .title {
          color: #fff !important;
          html.zanzibar &{
            color: @main-color-4!important;
          }
        }
      }
    }
  }
  .detail-inner {
    padding-top: 40px;
    position: relative;
    @media all and (max-width: 1439px) {
      width: 85%;
      margin: 0 auto;
    }
    @media @1024 {
      width: 100%;
    }
    @media @480 {
      padding-top: 30px;
    }
    @media @360 {
      padding-top: 20px;
    }
    .slick-next {
      right: 25px;
    }
    .slick-prev {
      left: 25px;
    }
    .detail-close {
      display: none;
      cursor: pointer;
      position: absolute;
      top: 25px;
      right: -68px;
      width: 85px;
      z-index: 4;
      @media @480 {
        top: 15px;
      }
      @media @360 {
        top: 5px;
      }
      .icon {
        padding: 8px;
        background: @main-color-4;
        color: #fff;
        border-radius: 50%;
        display: table;
        float: left;
      }
      .text {
        position: relative;
        right: -28px;
        top: 5px;
        color: @main-color-1;
        font-size: @font-size-14;
        @media @480 {
          display: none;
        }
      }
    }
    .detail-image-slider {
      width: @container - (2*@container-padding);
      @media @1024 {
        width: @container-1 - (2*@container-1-padding);
      }
      @media @768 {
        width: @container-2 - (2*@container-2-padding);
      }
      @media @480 {
        width: 480px - 2*@container-3-padding;
        width: calc(~"100vw" - 2*@container-3-padding);
        margin: 0 auto;
      }
      @media @360 {
        width: 360px - 2*@container-4-padding;
        width: calc(~"100vw" - 2*@container-4-padding);
        margin: 0 auto;
      }
      @media @320 {
        width: 320px - 2*@container-5-padding;
        width: calc(~"100vw" - 2*@container-5-padding);
        margin: 0 auto;
      }
      .item {
        position: relative;
        overflow: hidden;
        width: inherit;
        height: 530px;
        @media @1024 {
          height: 330px;
        }
        @media @480 {
          height: 230px;
        }
      }
      img {
        .image-center;
        transition: width 0.5s ease 0s;
      }
      .slick-slider {
        margin-bottom: 0;
        img {
          width: 100%;
          transition: width 0.5s ease 0s;
        }
      }
    }
    .detail-content {
      padding: 30px 0 30px 20px;
      box-sizing: border-box;
      @media @768 {
        padding: 30px 0;
      }
      .col-7 {
        @media @1024 {
          padding-right: 60px;
          box-sizing: border-box;
        }
        @media @768 {
          width: 100% !important;
          float: none !important;
          padding: 0;
          margin-bottom: 20px;
        }
      }
      .col-5 {
        padding-left: 60px;
        box-sizing: border-box;
        @media @1024 {
          padding-left: 0;
        }
        @media @768 {
          width: 100% !important;
          float: none !important;
        }
      }
      .heading {
        color: @main-color-1;
        font-size: @font-size-35;
        font-weight: @font-normal;
        font-family: @font-family-libre;
        margin: 0 0 15px;
        html.zanzibar &{
          color:@main-color-4;
          font-family: @font-fertigo;
        }

      }
      .sub-heading {
        font-size: @font-size-17;
        font-weight: normal;
        color: @main-color-1;
        display: block;
        margin: 0 0 10px;
        html.zanzibar &{
          color: #fff;
        }
      }
      p {
        font-size: @font-size-17;
        line-height: 22px;
        color: @main-color;
        margin: 0 0 20px;
      }
      .btn-type-1 {
        display: table;
        min-width: 175px;
      }
      .label-wrapper {
        margin: 0 -12px;
      }
      .label {
        width: 50%;
        padding: 3px 12px;
        box-sizing: border-box;
        @media @360 {
          width: 100%;
          float: none;
        }
        .label-inner {
          border: 1px solid @main-color-1;
          border-radius: 2px;
          box-sizing: border-box;
          padding: 10px 15px;
          html.zanzibar &{
            border:1px solid fade(#fff,20%);
          }
          .icon {
            float: left;
            width: 35px;
            color: @main-color-1;
            font-size: 18px;
            position: relative;
            top: 2px;
            html.zanzibar &{
              color:fade(#fff,75%);
            }
          }
          .text {
            font-size: @font-size-14;
            color: @main-color-1;
            html.zanzibar &{
              color:fade(#fff,75%);
            }
          }
        }
      }
    }
    .detail-footer {
      display: table;
      width: 100%;
      padding: 5px 0;
      border-top: 1px solid @main-color-4;
      border-bottom: 1px solid @main-color-4;
      margin-bottom: 20px;
      .col {
        box-sizing: border-box;
        padding: 15px 0;
        @media @768 {
          width: 50% !important;
          float: left !important;
        }
        &.text-left {
          border-right: 1px solid @main-color-4;
        }
      }
      .nav {
        position: relative;
        &.nav-left {
          .text {
            left: -80px;
          }
        }
        &.nav-right {
          .text {
            right: -80px;
          }
        }
        &:hover {
          .title {
            color: @main-color-3;
          }
        }
        .title {
          font-size: @font-size-20;
          font-family: @font-family-libre;
          color: @main-color;
          padding: 0 20px;
          transition: @transition-color;
          @media @360 {
            padding: 0 12px;
          }
        }
        .text {
          display: block;
          font-size: @font-size-14;
          line-height: 18px;
          color: @main-color-1;
          width: 60px;
          position: absolute;
          text-align: center;
          top: -5px;
          html.zanzibar &{
            color:fade(#fff,50%);
          }
          @media @480 {
            display: none;
          }
        }
      }
    }
  }
}

.select2-dropdown {
  border: 1px solid @main-color-9;
  border-radius: 0;
}

.select2-container--default .select2-results__option--highlighted[aria-selected] {
  background-color: @main-color-1;
}
.select2-search {
  display: none;
}

.trackingimg {
  display: none;
}

.orange{
  color: @main-color-4!important;
}
