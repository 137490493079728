#main-header {
  .image-background;
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1001;
  @media @1024 {
    min-height: 100px;
  }
  &:after {
    background: -moz-linear-gradient(top,  rgba(0,0,0,0.45) 0%, rgba(0,0,0,0) 100%); /* FF3.6+ */
    background: -webkit-gradient(linear, left top, left bottom, color-stop(0%,rgba(0,0,0,0.45)), color-stop(100%,rgba(0,0,0,0))); /* Chrome,Safari4+ */
    background: -webkit-linear-gradient(top,  rgba(0,0,0,0.45) 0%,rgba(0,0,0,0) 100%); /* Chrome10+,Safari5.1+ */
    background: -o-linear-gradient(top,  rgba(0,0,0,0.45) 0%,rgba(0,0,0,0) 100%); /* Opera 11.10+ */
    background: -ms-linear-gradient(top,  rgba(0,0,0,0.45) 0%,rgba(0,0,0,0) 100%); /* IE10+ */
    background: linear-gradient(to bottom,  rgba(0,0,0,0.45) 0%,rgba(0,0,0,0) 100%); /* W3C */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#73000000', endColorstr='#00000000',GradientType=0 ); /* IE6-9 */
    z-index: -1;
    height: 300px;
    pointer-events: none;
    @media @1024 {
      height: 100px;
    }
  }
  @media @768 {
    position: static;
    min-height: 0;
  }
  .header-mobile {
    display: none;
    @media @768 {
      display: block;
      height: 80px;
      position: absolute;
      z-index: 5;
      width: 100%;
      background: @header-bar-background-2;
      background: fade(@header-bar-background-2, 70%);
      .logo {
        display: block;
        //display: table;
        //color: #fff;
        //&[href]:hover {
        //  color: @main-color-4;
        //}

        .elm_logo {
          position: relative;
          width: 108px;
          margin: 5px auto 0;

          &:before {
            content: "";
            display: block;
            padding-bottom: 50/73*100%;
          }
          > img {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            width: 100%;
            height: 100%;
          }
        }
      }
      .icon {
        font-size: 74px;
        padding: 0 10px;
      }
    }
    @media @o768 {
      width: auto;
      background: transparent;
      position: fixed;
      left: 35%;
      z-index: 1002;
      .container {
        width: auto !important;
      }
    }
  }
  .header-bar {
    background: @header-bar-background;
    height: 36px;
    padding: 6px 0;
    box-sizing: border-box;
    border-bottom: 1px solid @main-color-1;
    border-bottom: 1px solid fade(@main-color-1, 50%);
    .icon-close {
      display: none;
    }
    @media @768 {
      display: none;
      background: @header-bar-background-2;
      height: 100%;
      position: fixed;
      width: 620px;
      left: -620px;
      bottom: 0;
      top: 0;
      z-index: 99999;
      overflow-y: scroll;
      transition: all 0.5s ease 0s;
      &.toggle {
        left: 0;
      }
      .icon-close {
        display: block;
        color: #fff;
        font-size: 30px;
        position: absolute;
        right: 10px;
        top: 5px;
        cursor: pointer;
      }
    }
    @media @480 {
      width: 320px;
      left: -320px;
      font-size: 1.1em;
    }
    @media @360 {
      width: 260px;
      left: -260px;
    }
    .header-bar-l {
      .icon {
        float: left;
        margin-right: 10px;
        top: 3px;
        position: relative;
        @media @768 {
          margin-right: 10px;
          margin-bottom: 20px;
          width: 20px;
          position: relative;
          top: 3px;
        }
      }
      .text {
        position: relative;
        vertical-align: middle;
        @media @768 {
          top: 0;
        }
      }
      @media @768 {
        float: none;
      }
      a:hover {
        .text {
          color: @main-color-1;
        }
      }
    }
    .header-bar-r {
      position: relative;
      top: 3px;
      @media @768 {
        float: none;
        position: static;
        top: 0;
      }
    }
    .title {
      font-size: @font-size-13;
      color: @main-color-1;
      letter-spacing: 0.075em;
      text-transform: uppercase;
      font-weight: bold;
      margin-right: 10px;
      position: relative;
      top: 1px;
      @media @768 {
        display: block;
        float: none;
        border-bottom: 1px solid @main-color-4;
        padding: 20px 20px 15px;
        box-sizing: border-box;
        margin-bottom: 20px;
        font-size: 1em;
        top: 0;
      }
    }
    .text {
      font-size: @font-size-14;
      letter-spacing: 0.025em;
      color: @header-bar-color;
      margin: 0 30px 0 0;
      transition: @transition-color;
      @media @768 {
        font-size: @font-size-16;
        margin: 0;
      }
    }
    @media @768 {
      .header-bar-nav {
        display: block !important;
        visibility: visible;
        ul {
          list-style: none;
          margin: 0;
          padding: 0 20px;
          li {
            a {
              font-size: @font-size-18;
              color: @header-bar-color;
              line-height: 31px;
              width: 100%;
              display: block;
              padding: 5px 0;
              &:hover, &.active {
                color: @main-color-1;
              }
            }
          }
        }
      }
    }
    .info {
      @media @768 {
        float: none;
      }
      .item {
        @media @768 {
          float: none;
          padding: 0 20px 20px;
          font-size: 13px;
          html.zanzibar &{
            font-size: 10px;
          }
        }
        span{
          &:before{
            html.zanzibar &{
              color: @main-color-4;
            }
          }
        }
      }
    }
    .lang {
      position: relative;
      width: 135px;
      @media @768 {
        float: none;
        display: table;
        margin: 0 17px 200px;
      }
      .text {
        margin: 0 8px 0 0;
      }
      .icon-flag {
        margin: 5px 15px 0 0;
      }
      .icon {
        font-size: 8px;
        color: #fff;
        position: relative;
        top: -3px;
      }
      .active {
        border: 1px solid transparent;
        cursor: pointer;
        transition: @transition-background, border 0.5s ease 0s;
        padding: 2px 2px 2px 6px;
        box-sizing: border-box;
        margin-top: -5px;
        text-align: right;
        @media @768 {
          display: none;
        }
        &:hover, &.hover {
          background: #333;
          background: fade(#999, 80%);
          border: 1px solid #000;
        }
        .icon {
          display: inline-block;
        }
        .fl {
          float: none !important;
          display: inline-block;
        }
        .text {
          vertical-align: text-top;
        }
      }
      .list {
        background: #333;
        background: fade(#999, 80%);
        position: absolute;
        width: 100%;
        padding: 0;
        list-style: outside none none;
        border: 1px solid rgb(0, 0, 0);
        box-sizing: border-box;
        margin: 0;
        border-top: 0;
        z-index: 4;
        @media @768 {
          display: block !important;
          background: transparent;
          border: 0;
        }
        li {
          padding: 5px 0 5px 5px;
          transition: @transition-background;
          &:hover {
            background: @main-color-2;
            a {
              color: #fff;
            }
          }
          a {
            display: table;
            width: 100%;
          }
          .icon-flag {
            top: 4px;
          }
        }
      }
    }
  }
  .header {
    padding: 15px 0;
    box-sizing: border-box;
    > .container {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
    &.fixed {
      position: fixed;
      background: @header-bar-background-2 !important;
      width: 100%;
      height: 65px;
      padding: 10px 0;
      top: 0 !important;
      box-shadow: 0 -10px 20px 5px #333;
      z-index: 1001;
      .logo {
        //font-size: 40px;
        //@media all and (min-width: 768px) {
        //  display: none;
        //}
        //&:before {
        //  content: @icon-zvon-logo-small;
        //  color: @main-color-1;
        //}

        .elm_logo {
          width: 72px;
        }
      }
      .header-r {
        float: left;
        .nav-menu {
          margin: 0 0 0 20px;
        }
      }
      .header-availability {
        display: block;
        position: relative;
        top: -2px;
      }
    }
    @media @768 {
      position: absolute;
      top: 80px;
      z-index: 5;
      width: 100%;
      background: @header-bar-background-2;
      background: fade(@header-bar-background-2, 70%);
      transition: @transition-background;
      height: 48px !important;
      padding: 0 !important;
      html.zanzibar & {
        top: 15px;
        background: transparent;
      }
      .header-availability {
        display: block;
        margin-top: 5px;
        .btn-type-1 {
          font-size: 14px;
          padding: 10px 20px;
          &:hover {
            background: @main-color-4;
          }
        }
      }
    }
    @media @o768 {
      position: fixed;
      top: 0;
      height: 84px !important;
      html.zanzibar & {
        background: @header-bar-background-2;
        background: fade(@header-bar-background-2, 70%);
        transition: @transition-background;
      }
      .container {
        margin-top: 15px;
      }
    }
    .header-mobile-scroll {
      display: none;
      @media @768 {
        display: block;
        float: left;
        margin-top: 14px;
      }
      .header-mobile-menu {
        cursor: pointer;
        .icon-menu {
          display: none;
          @media @768 {
            display: inline;
            color: @main-color-1;
          }
        }
        .text {
          font-size: 13px;
          text-transform: uppercase;
          color: #fff;
          font-weight: @font-bold;
          position: relative;
          top: -2px;
          left: 6px;
          @media @480 {
            top: 0;
          }
        }
      }
    }
    .header-availability {
      @media @768 {
        display: block;
      }
    }
    .logo {
      //font-size: 100px;
      //@media @1024 {
      //  font-size: 75px;
      //}
      //color: #fff;
      //text-shadow: 0 0 7px fade(#000, 25%);
      //&[href]:hover, &.active {
      //  color: @main-color-4;
      //}

      .elm_logo {
        position: relative;
        width: 146px;

        @media @1024 {
          width: 120px;
        }

        &:before {
          content: "";
          display: block;
          padding-bottom: 50/73*100%;
        }
        > img {
          position: absolute;
          left: 0;
          right: 0;
          top: 0;
          bottom: 0;
          width: 100%;
          height: 100%;
        }
      }

      @media @768 {
        display: none;
      }
    }
    .nav-menu {
      display: table;
      @media @1024 {
        font-size: 1em;
      }
      @media @768 {
        display: none;
        visibility: hidden;
      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li {
          float: left;
          @media @768 {
            float: none;
          }
          a {
            display: block;
            font-size: @font-size-14;
            letter-spacing: 0.012em;
            font-weight: 700;
            html.zanzibar &{
              @media @m1024 {
                font-size: @font-size-20;
              }
            }
            @media @1024 {
              font-size: @font-size-15;
            }
            padding: 10px;
            color: #fff;
            font-family: @font-family-new;
            text-transform: uppercase;
            position: relative;
            text-align: center;
            text-shadow: 0 0 7px fade(#000, 25%);
            //&.first {
            //  display: none;
            //}

            html.zanzibar &{
              color: #fff;
            }
            &:hover, &.active, &:active {
              color: @main-color-4;
              @media @768 {
                background: @main-color-4;
              }
            }
          }
        }
      }
    }
  }
}

html.zanzibar {
  .logo-mobile{
    display: none;
    @media @768 {
      display: block;
      max-height: 50px;
      position: relative;
      top: 10px;
    }
    @media @320 {
      max-height: 40px;
      left: -25px;
    }
  }
  .logo-desktop{
    display: none;
    @media @min959 {
      display: none;
    }

  }
  .logo-main{
    max-height: 124px;
    padding: 20px;
    @media @768 {
      max-height: 70px;
      padding: 0;
      display: none;
    }
  }
}
